/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import GSAuthorFooter from "components/Footers/GSAuthorFooter";
import YouTubeEmbed from "components/YoutubeEmbed";

function OAuthEntWhy() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title"> <i className="fa fa-lock" /> <br /> OAuth for the Enterprise - WHY? </h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h5 className="description">
                In this insightful video, we shed light on the pressing challenges confronting enterprises today and how OAuth serves as a transformative solution. 
                  <br /> <br />
                  Enterprises grapple with dispersed identity pools, where users navigate multiple systems, leading to complexity and security concerns. Non-standardized 
                  authentication and authorization processes exacerbate vulnerabilities, posing risks to sensitive data. Additionally, the disjointed development process 
                  further complicates matters. OAuth emerges as a beacon of resolution, offering a standardized approach to authentication and authorization, bridging 
                  identity silos, and streamlining development practices.
                  
                  <br /> <br />Join us as we navigate through these issues, showcasing how OAuth acts as a unifying force, enhancing security, and fostering a more 
                  efficient and cohesive enterprise environment.
                </h5>
                <br />
                <YouTubeEmbed videoId='oLJ17I3hh-w' />
               </Col>
            </Row>
          </Container>
          </div>
      
          {/* <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2_RSA</h2>
                <h5 className="description">

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div> */}
        </div>
      <GSAuthorFooter />
      <DemoFooter />
    </>
  );
}

export default OAuthEntWhy;
