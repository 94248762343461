/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import { Row, Container, Button } from "reactstrap";

function DemoFooter() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleLinkClick = (to) => {
    scrollToTop();
    history.push(to);
  };

  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <NavLink
                to="/"
                onClick={() => handleLinkClick('/')}
                >
                  Home
                </NavLink>
                
              </li>
          
            </ul>
          </nav>

          <div className="credits ml-auto" >
          <Button
            style={{padding: '7px 0px', fontSize: '17px'}}
            color="link"
            href="https://www.linkedin.com/in/grant-sims-19715752/"
            target="_blank"
          >
            <i className="fa fa-linkedin" />
          </Button>
          <Button
            style={{padding: '7px 2px', fontSize: '17px'}}
            color="link"
            href="https://twitter.com/ChiefRiverSims"
            target="_blank"
          >
            <i className="fa fa-twitter" />
          </Button>
          <Button
            style={{padding: '7px 2px', paddingRight: '12px', fontSize: '17px'}}
            color="link"
            href="https://www.youtube.com/@chiefriver"
            target="_blank"
          >
            <i className="fa fa-youtube-play" />
          </Button>
              ChiefRiver Solutions
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
