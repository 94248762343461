/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Weaving IT Excellence into Your Business</h2>
                <h5 className="description">
                Specializing in assisting customers to architect secure IT solutions with a 
                commitment to seamless delivery. Our strategic approach blends technology 
                expertise with a deep understanding of client needs, resulting in customized 
                solutions that elevate operational efficiency and cybersecurity resilience.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="/services"
                >
                  See Details
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-puzzle-piece" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Digital Presence</h4>
                    <p className="description">
                      Specializing in comprehensive web development and hosting services, 
                      crafting tailored online solutions
                      for optimal digital presence.
                      
                    </p>
                    <Button className="btn-link" color="info" href="/services#DigitalPresence">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Risk Assesments</h4>
                    <p>
                      Identify and evaluate potential threats, providing you with strategic 
                      insights to safeguard your assets and operations
                    </p>
                    <Button className="btn-link" color="info" href="/services#RiskAssesments">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-user-secret" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Security Training</h4>
                    <p>
                      Delivering focused programs to foster awareness and equip your staff with essential skills 
                      for navigating today's digital threats
                    </p>
                    <Button className="btn-link" color="info" href="/services#SecurityTraining">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-cloud" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Cloud Security</h4>
                    <p>
                      Comprehensive assessments and strategic solutions tailored to 
                      safeguard your digital assets and data in the cloud
                    </p>
                    <Button className="btn-link" color="info" href="/services#CloudSecurity">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>

            <Row>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="/about" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/chiefriver/gsimsface.png")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="/about" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Grant Sims</CardTitle>
                        <h6 className="card-category">CTO</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                    I am genuinely excited about tackling complex challenges and 
                    delivering valuable solutions, as it allows me to continuously
                    contribute meaningful impact to others' success and satisfaction.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/grant-sims-19715752/"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="https://twitter.com/ChiefRiverSims"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="https://www.youtube.com/@chiefriver "
                      target="_blank"
                    >
                      <i className="fa fa-youtube-play" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="What can we help secure?"
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
