/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import GSAuthorFooter from "components/Footers/GSAuthorFooter";

function TLSAuth() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title"> <i className="fa fa-lock" /> <br /> TLS Server Authentication </h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h2 className="title">The Background</h2>
                <h5 className="description">
                  I did a previous post on the basic flow of a TLS session that you can find here: <br />
                   <a href="https://www.linkedin.com/posts/grant-sims-19715752_https-giving-a-narrative-to-the-establishment-activity-7140772655586082816-xe30" 
                   style={{color:'darkblue',fontWeight: 'bold'}}
                   target='_blank'
                   rel="noreferrer" >
                    TLS Narrative
                   </a>
                  <br /><br />
                  From a security perspective I wanted to dive deeper to have a complete understanding of answering a fundamental question as it relates
                  to the security of a TLS conversation or what most would think of as the “Lock” in the address bar of our browsers 
                  <br /><br />
                  How can we be sure we are talking to the server who we intended to connect to?
                  <br /><br />
                  We wouldn’t want to THINK we are talking to our bank application but really talking to a bad guy who is just relaying and manipulating connections to our actual bank on our behalf!

                  This should be simple to break down right? Not so fast!!! (channelling Lee Corso 🙂)

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-left">
          <Container>
            <Row style={{ display: 'inline-block' }} >
              <Col className="ml-auto mr-auto" md="14" style={{paddingLeft: '10px', paddingRight:  '10px'}}>
                <h2 className="title">TL;DR - Points of Server Authentication in TLS</h2>
                <h5 className="description">
                  <b>TLS1.2 RSA - Client Encrypted Premaster Secret</b><br /><br />

                  The client sends the pre-master secret required to derive session keys, encrypted with the server's public key. If the server 
                  cannot decrypt this value with the servers private key it won't be able to create matching session keys. This process ensures 
                  the server possesses the private key (Server Authentication)
                  <br /><br />

                  <b>TLS1.2 DH - Mathematical Computation of DH Session Key</b><br /><br />
                  
                  The server wouldn't be able to compute matching session keys through the Diffiehellman algorithm if it didn't possess the DH 
                  private key (It really wouldn't be able to create a compatible public key).This inherent DH computation ensures the server 
                  possesses the private key (Server Authentication)
                  <br /><br />

                  <b>TLS1.2 DHE - Signed DHE Parameters</b><br /><br />

                  DHE Parameters are signed and then sent to the client from the server via the Server Key Exchange. If the client couldn't decrypt 
                  the DHE signed parameters (with the server's public key) the client would never be able to compute the same session key as the server. 
                  This signature verification of DHE parameters ensures the server possesses the private key (Server Authentication)
                  <br /><br />

                  <b>TLS1.3 DHE - Signed Hash of the TLS Handshake</b><br /><br />

                  After the server provides its public certificate it must follow that record with a Certificate Verify record. This record contains a 
                  signed hash value of the TLS conversation thus far. The flow has an explicit step that the client must decrypt the hash value in the 
                  Certificate Verify record and ensure it matches its own hash computation of the TLS conversation. This is the most explicit check. If 
                  the hash value cant be decrypted with the server's public key or the hash doesn't match the clients computation, the TLS handshake is 
                  terminated. This signature verification ensures the server possesses the private key (Server Authentication).



                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
        <div className="section text-left">
          <Container>
            <Row style={{ display: 'inline-block' }} >
              <Col className="ml-auto mr-auto" md="14" style={{paddingLeft: '10px', paddingRight:  '10px'}}>
                <h2 className="title">Prerequisite Knowledge</h2>
                <h5 className="description">
                <ul>
                  <li>Asymmetric key pairs (RSA/DSS) understanding</li>
                  <li>Asymmetric encryption/signing</li>
                  <ul>
                    <li>Signing is the signer encrypting data with their PRIVATE key</li>
                    <li>Signature is verified by an entity by decrypting the data with the signer’s PUBLIC key</li>
                  </ul>
                  <li>Symmetric encryption is when a key is used on subject data to both encrypt and decrypt</li>
                  <li>Diffie-Hellman key exchange, high-level understanding</li>
                  <li>Client in this article is a web browser</li>
                </ul>
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h2 className="title">Intentions of the Article</h2>
                <h5 className="description">
                
                  What this article is NOT about is TLS confidentiality and not about the benefits of certain cipher suites (RSA vs DH, e.g. perfect forward secrecy.)
                  <br /><br />
                  I want to explicitly focus on the server AUTHENTICATION aspect of a TLS connection as this perplexed me the deeper I went…
                   <br /><br />
                  To summarize the way a server authenticates itself is two-fold:

                  <ol>
                    <li>
                      The server identifies itself (CommonName or SubAltName) within a public cert which it provides to the client. We can verify this info is accurate as the info provided from the server is trusted (signed) by someone our system already has a pre-configured trust (Public Root CA)... We trust someone (Root CA) that says we should trust this server (Public Cert of the Server, Signed by our Root CA)
                    </li>
                    <li>
                      The client then needs to verify that the info the server provided us above is actually owned by the server we have a connection with and not some imposter! All the info in the first bullet is public info and we need to verify this public info in some way.
                    </li>
                  </ol>

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">The First Bullet - Should I Trust You?</h2>
                <h5 className="description">
                The first bullet is, for the most part, straight forward even as we discuss different TLS versions and cipher suites within. 
                <br /><br />
                <img src={require("assets/img/chiefriver/blog/tlsauth/servercert.png")} 
                  alt="ServerCert"  
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                <br /><br />
                As the above picture shows the CLIENT and SERVER both say hello to each other and then the SERVER will send its PUBLIC certificate…
                <br /><br />
                At this time we perform the check of the first bullet with the summary actions below:
                <br />
                <ul>
                  <li>
                    Investigate the server cert and look at the CommonName (CN) or SubjectAltNames (SAN) and make sure it matches what we typed in our address bar
                    <br />
                    <img src={require("assets/img/chiefriver/blog/tlsauth/lockaddressbar.png")} alt="ServerCert"  /> 
                     <span style={{ fontSize: '3em', fontWeight: 'bold', verticalAlign: 'middle'  }}>=</span>
                    <img src={require("assets/img/chiefriver/blog/tlsauth/SAN.png")} 
                      alt="ServerCert" 
                      style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                      />
                  </li>
                  <li>
                    Walk the rest of the cert chain to make sure we can trust the info above.  We do this by validating the certificates of the cert chain until we reach a 
                    signature of a trusted root Certificate Authority which we have in our OS or browser certificate store.
                  </li>
                </ul>
                These cert stores are prepopulated with Root CAs
                 that we trust to identify websites for us and have processes for validating ownership of domains (e.g. chiefriver.com)
                 <br /><br />

                At this point everything has checked out… The domain in the server cert matches what we typed into the address bar (www.chiefriver.com) and we know this info
                is accurate because someone we trust (Amazon Root CA 1) signed an Intermediate CA (Amazon RSA 2048 M03) that signed our server cert (videos.chiefriver.com) 
                that has a SAN cert with a DNS name of www.chiefriver.com
                <br /><br />

                Cert chain served from the web server
                <img src={require("assets/img/chiefriver/blog/tlsauth/certchain.png")} alt="ServerCert" 
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                />
                <br /><br />
                RootCA we trust in our browser Cert Store
                <br /><br />
                <img src={require("assets/img/chiefriver/blog/tlsauth/rootca.png")} alt="ServerCert"
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                />
                <br /><br /><br />
                Now the not-so-straightforward check which is our second bullet... 
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h2 className="title">The Second Bullet - Are You an Imposter?</h2>
                <h5 className="description">
                How do we know what we just verified should actually be possessed/served from the website we made a connection to? How do we know it's not 
                someone or some server posing as this site (e.g. https://chiefriver.com). Remember everything up to this point that we validated is public 
                information and anyone could have pulled this down from the legit site and started impersonating. 
                <br /><br/>
                  So we need to now authenticate this server and the way we do so depends on many factors
                  <ul>
                    <li>
                       The TLS version (e.g. 1.2 or 1.3)
                    </li>
                    <li>
                       The cipher suite for the above TLS version (RSA, DH, DHE)
                    </li> 
                  </ul>
                  <br />
                  Let’s start with the most straightforward, but least secure (TLS1.2_RSA), then get a bit more complex, but more secure (TLS1.2 DH & DHE),
                  and then come back to the most recent, most secure and most straightforward implementation (TLS1.3_DHE) 🙂.
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2_RSA</h2>
                <h5 className="description">
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls12rsa.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                  <br /><br />

                  Without going into the whole TLS conversation…. The Public and Private Key pair used in the “Server Certificate” record which we discussed above 
                  is directly involved in the authentication of the server. Or more accurately the client can verify that the server possesses the corresponding private
                   key to the public key (and certificate attributes) we validated previously. 
                  <br /><br />
                  !!!The Private Key is called private for a reason and should always be kept secret and secured. Otherwise the whole process here and others throughout
                   this article are negated!!!
                  <br /><br />
                  Authentication of the server: Once the client/browser receives the public cert from the server it then computes a pre-master secret which will be used
                   later as a seed to create symmetric encryption keys for encrypted communication. 
                  <br /><br />
                  Once it computes this pre-master it SIGNS (encrypts) the value with the servers PUBLIC Key… This means ONLY the systems that possess the corresponding
                   PRIVATE Key can retrieve this value… And only a system that can get this pre-master value can compute the accurate symmetric encryption key(s) (session keys)
                    required to make a successful TLS/secure connection. 
                  <br /><br />
                  Therefore, as long as the TLS handshake is successful, we the client can be assured this is the true owner of the certificate provided to us and is the
                   website we intended to connect with!
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2 DiffieHellman (DH) (Static)</h2>
                <h5 className="description">
                  Static DiffieHellman… Not going to go into the details of DH but after you are comfortable with the high level concept realize this cipher uses STATIC DH keys which
                  are embedded in the servers Public/Private Certificate that it serves during the “Server Certificate” message
                  <br />
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls12fixedDH.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                  />
                  <br />
                  Similar to the RSA flow above the servers Public and Private Cert, more so the static DH public and Private Keys within the cert are involved in the authentication of
                   the server by way of requiring them to compute the shared symmetric keys required for a successful TLS/Secure connection. 
                  <br /><br />
                  The real difference here vs the RSA flow above…
                  <ul>
                    <li>
                      The Public/Private Key pair used as part of the auth is not a public/private cert (RSA or DSS) but the DH key pair, precomputed with static P & G values and that the public key is signed by a CA
                    </li>
                    <li>
                      DiffieHellman session key computation vs RSA Pre-master Key
                    </li>
                  </ul>
                  Without going deep into DiffieHellman, the concept of server authentication remains similar in that without the Private Key being possessed by the server it would 
                  never be able to compute the same session key as the client. If the server can't compute the same session key then the TLS handshake would ultimately fail. 
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2 DiffieHellman (DHE) (Ephemeral)</h2>
                <h5 className="description">
                This is where things change a bit. Because the Public/Private server key pair is not directly used in symmetric key generation there is a specific SIGNATURE step that
                 is introduced to prove ownership of the private key, to authenticate the server.
                 <br /><br />
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls12ephemdh.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto',  }}
                  />
                  <br /> <br />
                  Packet Capture
                  <br /><br />
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls12wireshark.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto',  }}
                  />
                  <br /><br />
                 As shown in the diagram, because the initial public/private certificate and key pair is not utilized for session key computation, we must inject a new process to perform
                  this server authentication. We still need to share some public DiffieHellman parameters (e.g. P, G and server public DH values) to derive session keys so what we do in this flow is the server will SIGN/Encrypt
                   these DH parameters with the servers PRIVATE key. Once the client receives these parameters it will then use the server's PUBLIC key provided earlier in the “Server Certificate”
                    record to decrypt/verify the signature of the server. This action of decrypting the DH params sent by the server acts as the authentication of the server and ensures us that
                     the server possesses the corresponding private certificate!

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.3 DiffieHellman (DHE) (Ephemeral)</h2>
                <h5 className="description">
                  And now to finish with TLS1.3 DHE! First and foremost, RSA and DH (static) key exchange are no longer allowed due to lax security! Everything will be DHE and ensure Perfect 
                  Forward Secrecy (another lesson in itself)! 
                  <br /><br />
                  The verification is similar to the above TLS1.2 DHE but occurs in its own more obvious TLS1.3 record.
                  <br /><br />
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls13.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto',  }}
                  />
                  <br /><br />
                  Packet Capture
                  <br /><br />
                  <img src={require("assets/img/chiefriver/blog/tlsauth/tls13wireshark.png")} alt="ServerCert" 
                    style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto',  }}
                  />
                  <br /><br />
                  Rather than signing some DH params, TLS sends those earlier in the TLS1.3 flow (client and server hello) to reduce the round trip time to create a TLS session. Instead, right
                   after the server sends the public cert in the “Certificate” record, the server follows that up with a “Certificate Verify” record. This record includes a signed (encrypted 
                   with the server's Private Key) HASH of the entire TLS conversation thus far. Once this record gets to the client then the client must use the server's PUBLIC key to decrypt 
                   this hash and ensure it matches its own calculated hash of the TLS conversation thus far. This action of decrypting the hash of the TLS conversation thus far, sent by the 
                   server, acts as the authentication of the server and ensures us that the server possesses the corresponding private certificate!

                   <br /><br />
                  I hope you made it this far and can rest assured that the “Lock” in your address bar is more than just an icon 🙂



                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">I hope you made it this far and can rest assured that the “Lock” in your address bar is more than just an icon 🙂</h2>
                <br />
              </Col>
            </Row>
          </Container>
          </div>
          {/* <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2_RSA</h2>
                <h5 className="description">

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div> */}
        </div>
      <GSAuthorFooter />
      <DemoFooter />
    </>
  );
}

export default TLSAuth;
