/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import GSAuthorFooter from "components/Footers/GSAuthorFooter";
import YouTubeEmbed from "components/YoutubeEmbed";

function Hashes() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title"> <i className="fa fa-lock" /> <br /> Hash Usecases - Email & Passwords </h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h5 className="description">
                In this comprehensive video on the utility of hashes in the realm of cybersecurity, we delve into the fundamental role they play in fortifying the security of passwords and emails. 
                <br /> <br />
                Offering a nuanced understanding of cryptographic hashing, the video explores how hashes transform sensitive information into irreversible codes, enhancing data protection and fortifying the defense against unauthorized access. From password storage to email integrity, viewers will gain insights into the critical safeguards provided by hashes, ensuring a robust and resilient security framework in the digital landscape.
                </h5>
                <br />
                <YouTubeEmbed videoId='uYmXAX4n0RE' />
               </Col>
            </Row>
          </Container>
          </div>
      
          {/* <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2_RSA</h2>
                <h5 className="description">

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div> */}
        </div>
      <GSAuthorFooter />
      <DemoFooter />
    </>
  );
}

export default Hashes;
