/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ServicesPageHeader from "components/Headers/ServicesPageHeader";

function ServicesPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ServicesPageHeader />
      <div className="main">
        <div className="section text-left" id="DigitalPresence">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h2 className="title"> <i className="fa fa-puzzle-piece" style={{ marginRight: '10px' }}/> Digtial Presence</h2>
                <h5 className="description">
                  Elevate your brand with our Digital Presence service, a comprehensive solution designed to 
                  enhance and optimize your online footprint. We specialize in crafting a compelling digital 
                  identity that resonates with your audience, driving engagement and maximizing your online impact.
                  <br /><br />
                  <ul>
                    <li><strong>Web Development:</strong> Create and optimize user-friendly websites that align with your brand and business goals.</li>
                    <li><strong>Hosting Services:</strong> Provide reliable and secure hosting solutions to ensure seamless performance for your online presence.</li>
                    <li><strong>Web Optimization:</strong> Ensure your website is visually appealing and optimized for search engines.</li>
                    <li><strong>Content Strategy:</strong> Implement a targeted content strategy to effectively communicate your message and engage your online community.</li>
                </ul>
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-left" id="RiskAssesments">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title"> <i className="fa fa-lock" style={{ marginRight: '10px' }}/> Risk Assesments</h2>
                <h5 className="description">
                 Our risk assessment service is a meticulous examination of potential threats and 
                 vulnerabilities within your business, providing a comprehensive analysis to fortify
                 your security posture. We specialize in identifying, evaluating, and mitigating 
                 risks to safeguard your assets and ensure business continuity.
                 <br /><br />
                <ul>
                  <li><strong>Thorough Identification:</strong> Pinpoint potential risks and vulnerabilities across your digital and physical domains.</li>
                  <li><strong>Strategic Evaluation:</strong> Evaluate the severity and potential impact of identified risks on your operations.</li>
                  <li><strong>Tailored Mitigation Strategies:</strong> Develop customized strategies to effectively mitigate and manage identified risks.</li>
                  <li><strong>Ongoing Monitoring:</strong> Implement continuous monitoring processes to adapt and respond to evolving risks in real-time.</li>
                </ul>
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-left" id="SecurityTraining">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">  <i className="fa fa-user-secret" style={{ marginRight: '10px' }}/>  Security Training</h2>
                <h5 className="description">
                  Enhance your organizational security with our Security Training service, a comprehensive 
                  program designed to equip your team with the knowledge and skills needed to safeguard against 
                  evolving threats. We specialize in providing tailored training solutions that empower your 
                  workforce to mitigate risks and ensure a secure operational environment.
                  <br /><br />
                  <ul>
                      <li><strong>Cybersecurity Modules:</strong> Deliver targeted training modules covering the latest cybersecurity principles and best practices.</li>
                      <li><strong>Hands-On Workshops:</strong> Engage participants with practical, hands-on workshops to reinforce security concepts and skills.</li>
                      <li><strong>Customized Training Plans:</strong> Develop personalized training plans to address specific security needs within your organization.</li>
                      <li><strong>Ongoing Assessment:</strong> Implement regular assessments and evaluations to measure the effectiveness of the training program and identify areas for improvement.</li>
                  </ul>
                  
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-left" id="CloudSecurity">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title"> <i className="fa fa-cloud" style={{ marginRight: '19px' }}/> Cloud Security</h2>
                <h5 className="description">
                  Safeguard your cloud infrastructure with our Cloud Security Service, 
                  a specialized offering designed to fortify your digital assets against 
                  evolving threats in the cloud environment. We excel in providing tailored 
                  security solutions that ensure the confidentiality, integrity, and availability 
                  of your data stored in the cloud.
                  <br /><br />
                  <ul>
                    <li><strong>Cloud Risk Assessment:</strong> Conduct thorough assessments to identify and mitigate potential security risks in your cloud infrastructure.</li>
                    <li><strong>Secure Configuration:</strong> Implement and maintain secure configurations for cloud services to prevent vulnerabilities.</li>
                    <li><strong>Incident Response Planning:</strong> Develop comprehensive incident response plans tailored for cloud-based security incidents.</li>
                    <li><strong>Continuous Monitoring:</strong> Implement continuous monitoring mechanisms to detect and respond to security threats in real-time within your cloud environment.</li>
                </ul>
                </h5>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="What can we help secure?"
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
      <DemoFooter />
    </>
  );
}

export default ServicesPage;
