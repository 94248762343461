import React from 'react';

const YouTubeEmbed = ({ videoId }) => {
  return (
    <div>
      <iframe
        title="YouTube Video"
        className="youtube-embed-video"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
