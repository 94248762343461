/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import ServicesPage from "views/examples/ServicesPage";
import BlogLanding from "views/blog/BlogLanding";
import TLSAuth from "views/blog/TLSAuth";
import OAuthEntWhy from "views/blog/OAuthEntWhy";
import Hashes from "views/blog/Hashes";
import HTTPSFlow from "views/blog/HTTPSFlow";
import OAuthEntHow from "views/blog/OAuthEntHow";
// others

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<ProfilePage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/articles" element={<BlogLanding />} />
      <Route path="/articles/TLSAuth" element={<TLSAuth />} />
      <Route path="/articles/OAuthWhy" element={<OAuthEntWhy />} />
      <Route path="/articles/OAuthHow" element={<OAuthEntHow />} />
      <Route path="/articles/Hashes" element={<Hashes />} />
      <Route path="/articles/HTTPSFlow" element={<HTTPSFlow />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);
