/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import GSAuthorFooter from "components/Footers/GSAuthorFooter";
import YouTubeEmbed from "components/YoutubeEmbed";

function HTTPSFlow() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title"> <i className="fa fa-lock" /> <br /> HTTPS Flow with a Narrative </h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
        <div className="section text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
              {/* <h2 className="title"></h2> */}
                <h5 className="description">
                Embark on a captivating journey through the intricate landscape of online security in our enlightening video on the HTTPS flow. 
                <br /> <br />
                This narrative-driven exploration unravels the seamless and secure communication process between users' browsers and websites. 
                From the initiation of the connection to the encryption of data, the video unravels the HTTPS protocol, ensuring a gripping 
                understanding of how it safeguards sensitive information, maintains data integrity, and guarantees user privacy. Join us as 
                we demystify the HTTPS flow, empowering viewers with the knowledge to navigate the digital realm with confidence and security.
                </h5>
                <br />
                <YouTubeEmbed videoId='1u0M9hxK8N8' />
               </Col>
            </Row>
          </Container>
          </div>
      
          {/* <div className="section section-dark text-left">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="14">
                <h2 className="title">TLS1.2_RSA</h2>
                <h5 className="description">

                </h5>
                <br />
              </Col>
            </Row>
          </Container>
          </div> */}
        </div>
      <GSAuthorFooter />
      <DemoFooter />
    </>
  );
}

export default HTTPSFlow;
