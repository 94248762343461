/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function BlogLanding() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Articles of Knowledge</h2>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">TLS Server Authentication</h4>
                    <p className="description">
                      Dive deep into understand how server authentication is accomplished
                      with TLS1.2 & TLS1.3 through variouse cipher suites (RSA, DH, DHE)
                    </p>
                    <Button className="btn-link" color="info" href="/articles/TLSAuth">
                      Full Article
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Hash Usecases <br /> Email & Passwords</h4>
                    <p className="description">
                    Explore the vital role of hashes in safeguarding passwords and emails, unraveling their 
                    cryptographic significance and the crucial impact they have on security in this educational 
                    video.
                    </p>
                    <Button className="btn-link" color="info" href="/articles/Hashes">
                      Video
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">HTTPS flow with a Narrative</h4>
                    <p className="description">
                    Unraveling the secure communication process between a user's browser 
                    and a website through encryption, ensuring data integrity and privacy 
                    in online interactions.
                    </p>
                    <Button className="btn-link" color="info" href="/articles/HTTPSFlow">
                      Video
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
            <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">OAuth for the Enterprise - WHY</h4>
                    <p className="description">
                    Addressing crucial gaps in enterprise security and user authentication & authroization. 
                    Deep dive into the issues we face today that OAuth can help solve for. 
                    </p>
                    <Button className="btn-link" color="info" href="/articles/OAuthWhy">
                      Video
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="fa fa-lock" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">OAuth for the Enterprise - HOW</h4>
                    <p className="description">
                    Delve into the compelling benefits of adopting OAuth for enterprises, 
                    exploring how it bolsters security, optimizes user experience, and establishes a unified 
                    authentication framework across diverse platforms
                    </p>
                    <Button className="btn-link" color="info" href="/articles/OAuthHow">
                      Video
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      <DemoFooter />
    </>
  );
}

export default BlogLanding;
