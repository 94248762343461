/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";


function GSAuthorFooter() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
        <div className="section section-dark text-center">
          <Container>

            <Row>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="/about" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/chiefriver/gsimsface.png")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="/about" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Author: Grant Sims</CardTitle>
                        <h6 className="card-category">12/11/2023</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/grant-sims-19715752/"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="https://twitter.com/ChiefRiverSims"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="https://www.youtube.com/@chiefriver "
                      target="_blank"
                    >
                      <i className="fa fa-youtube-play" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
}

export default GSAuthorFooter;
